import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { minMedia } from "container/global.styles";

import { MediaContext } from "context/media.context";

const Container = styled.div`
    display: block;
    height: 0;
    opacity: 0;
    width: 0;

    ${minMedia.desktop`
        opacity: 1;
    `};
`;

export const MediaProvider = ({ children }) => {
    const [isDesktop, setIsDesktop] = useState(false);
    const ref = useRef(null);

    const onResize = () => {
        if (ref.current) setIsDesktop(getComputedStyle(ref.current).opacity === "1");
    };

    useEffect(() => {
        if (typeof document !== "object" || typeof window !== "object") {
            return;
        }

        onResize();
        window.addEventListener("resize", onResize);

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [ref]);

    return (
        <MediaContext.Provider value={{ isDesktop }}>
            <Container ref={ref} />

            {children}
        </MediaContext.Provider>
    );
};

MediaProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default MediaProvider;
