import "core-js/stable";
import "regenerator-runtime/runtime";
import "../assets/css/font.css";
import "../assets/css/style.css";

import React from "react";
// import App from "next/app";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import { Theme } from "container/global.definitions";

import { DeviceOutputProvider } from "provider/device-output.provider";
import { GlobalProvider } from "provider/global.provider";
import { MediaProvider } from "provider/media.provider";

const MyApp = ({ Component, pageProps }) => {
    const { global } = pageProps;

    return (
        <DeviceOutputProvider>
            <ThemeProvider theme={Theme}>
                <MediaProvider>
                    <GlobalProvider global={global}>
                        <Component {...pageProps} />
                    </GlobalProvider>
                </MediaProvider>
            </ThemeProvider>
        </DeviceOutputProvider>
    );
};

MyApp.propTypes = {
    Component: PropTypes.any.isRequired,
    pageProps: PropTypes.any.isRequired,
};

export default MyApp;
