export class DeviceOutputEnum {
    static APP = "app";

    static TABLET_APP = "tabletapp";

    static DESKTOP = "desktop";

    static TABLET = "tablet";

    static MOBILE = "mobile";
}

export default DeviceOutputEnum;
