import { createGlobalStyle } from "styled-components";

import CssRuleHelper from "helper/css-rule-helper";

import { Theme } from "./global.definitions";

const { minMediaSizes, maxMediaSizes } = Theme;

export const minMedia = CssRuleHelper.acquireMinMedia(minMediaSizes);
export const maxMedia = CssRuleHelper.acquireMinMedia(maxMediaSizes);

export const GlobalStyle = createGlobalStyle``;

export default {
    GlobalStyle,
    minMedia,
    maxMedia,
};
