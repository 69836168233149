export const Colors = {
    GREEN_92C0B7: "#92C0B7",
    GREEN_14CCB7: "#14CCB7",
    GREEN_E2F1EE: "#E2F1EE",
    BLUE_ECF7FD: "#ECF7FD",
    BLUE_DFEAF7: "#DFEAF7",
    BLUE_7EB6DE: "#7EB6DE",
    BLUE_459ABB: "#459ABB",
    BLUE_0271C2: "#0271C2",
    BLUE_005EA8: "#005EA8",
    BLUE_015FA4: "#015FA4",
    BLUE_0A5A7A: "#0A5A7A",
    BLUE_063773: "#063773",
    PURPLE_406591: "#406591",
    RED_C82D2D: "#C82D2D",
    RED_FADBD2: "#FADBD2",
    YELLOW_FFF8C9: "#FFF8C9",
    YELLOW_FFECB9: "#FFECB9",
    GREY_333333: "#333333",
    GREY_444444: "#444444",
    GREY_666666: "#666666",
    GREY_575757: "#575757",
    GREY_999999: "#999999",
    GREY_B4B4B4: "#B4B4B4",
    GREY_CDCDCD: "#CDCDCD",
    GREY_DADADA: "#DADADA",
    GREY_DCDCDC: "#DCDCDC",
    GREY_EFEFEF: "#EFEFEF",
    GREY_FAFAFA: "#FAFAFA",
    WHITE_FFFFFF: "#FFFFFF",
};

export default Colors;
