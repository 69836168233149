import { css } from "styled-components";

export default class CssRuleHelper {
    static acquireMinMedia = (sizes) =>
        Object.keys(sizes).reduce((acc, label) => {
            acc[label] = (...args) => css`
                @media (min-width: ${sizes[label]}px) {
                    ${css(...args)};
                }
            `;
            return acc;
        }, {});

    static acquireMaxMedia = (sizes) =>
        Object.keys(sizes).reduce((acc, label) => {
            acc[label] = (...args) => css`
                @media (max-width: ${sizes[label]}px) {
                    ${css(...args)};
                }
            `;
            return acc;
        }, {});
}
