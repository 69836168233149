import { Colors } from "./global.colors";

export const Theme = {
    fontFamily: "Open Sans, Verdana, sans",
    backgroundColor: Colors.WHITE_FFFFFF,
    zIndex: {
        menu: 100,
        modalClose: 100,
        overlay: 500,
    },
    fontWeight: {
        light: 300,
        regular: 400,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
    },
    maxMediaSizes: {
        desktop: 1600,
        mobile: 994,
    },
    minMediaSizes: {
        desktop: 994,
        mobile: 320,
    },
};

export default Theme;
