import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

import { DeviceOutputContext } from "context/device-output.context";

import { CookieKeyEnum } from "enumeration/cookie-key.enum";
import { DeviceOutputEnum } from "enumeration/device-output.enum";
import { QueryKeyEnum } from "enumeration/query-key.enum";

export const DeviceOutputProvider = ({ children }) => {
    const router = useRouter();
    const [cookies, setCookie, removeCookie] = useCookies([CookieKeyEnum.DEVICE_OUTPUT]);
    const [isApp, setIsApp] = useState(null);

    useEffect(() => {
        const { query } = router;
        const appKeys = [DeviceOutputEnum.APP, DeviceOutputEnum.TABLET_APP];
        const nonAppKeys = [DeviceOutputEnum.MOBILE, DeviceOutputEnum.DESKTOP, DeviceOutputEnum.TABLET];

        const hasAppQuery = appKeys.includes(query[QueryKeyEnum.DEVICE_OUTPUT]);
        const hasNonAppQuery = nonAppKeys.includes(query[QueryKeyEnum.DEVICE_OUTPUT]);
        const hasAppCookie = appKeys.includes(cookies[CookieKeyEnum.DEVICE_OUTPUT]);

        if (hasAppQuery) setCookie(QueryKeyEnum.DEVICE_OUTPUT, query[QueryKeyEnum.DEVICE_OUTPUT], { path: "/" });
        if (hasNonAppQuery) removeCookie(QueryKeyEnum.DEVICE_OUTPUT, { path: "/" });

        setIsApp(!hasNonAppQuery && (hasAppQuery || hasAppCookie));
    }, [cookies, removeCookie, router, setCookie]);

    return <DeviceOutputContext.Provider value={{ isApp }}>{children}</DeviceOutputContext.Provider>;
};

DeviceOutputProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default DeviceOutputProvider;
