import React, { useEffect, useState } from "react";
import fetchJsonp from "fetch-jsonp";
import PropTypes from "prop-types";

import { GlobalContext } from "context/global.context";

import fallbackJson from "sections/stats/stats.json";

export const GlobalProvider = ({ global, children }) => {
    const [json, setJson] = useState(fallbackJson);

    useEffect(() => {
        const url = process.env.NEXT_PUBLIC_CH24_HILFT_SSO_CONNECT_URL + "/donation/global"
        fetchJsonp(url)
            .then((response) => {
                return response.json();
            })
            .then((jsonResponse) => {
                setJson(jsonResponse);
            })
            .catch(() => {
                // eslint-disable-next-line no-console
                console.error("couldn't fetch donations json");
            });
    }, []);

    const { donationAmountUserValue, donationAmountC24Value } = json.pointdonations;

    return (
        <GlobalContext.Provider value={{ ...global, donationAmountUserValue, donationAmountC24Value }}>
            {children}
        </GlobalContext.Provider>
    );
};

GlobalProvider.defaultProps = {
    global: {},
};

GlobalProvider.propTypes = {
    global: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default GlobalProvider;
